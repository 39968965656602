<template>
  <div class="m-2">
    <label for="attachment" class="mt-2">Select an attachment:</label>
    <b-form-select id="attachment" v-model="attachment">
      <option :value="null">No attachment</option>
      <option v-for="note in contentOnlyNotes" :key="note.id" :value="note.content">{{ note.title }} ({{ note.content.length }})</option>
    </b-form-select>
    <!-- select a question or a task -->
    <label for="question-or-task" class="mt-2">Select a question or a task:</label>
    <b-form-select id="question-or-task" v-model="questionOrTask">
      <option value="question">Question</option>
      <option value="task">Task</option>
    </b-form-select>
    <div v-if="questionOrTask === 'task'">
      <label for="task" class="mt-2">Enter your task:</label>
      <b-form-textarea id="task" v-model="task"></b-form-textarea>
    </div>
    <div v-if="questionOrTask === 'question'">
      <label for="question" class="mt-2">Enter your question:</label>
      <b-form-textarea id="question" v-model="question"></b-form-textarea>
    </div>
    <label for="language-model" class="mt-2">Language model:</label>
    <b-form-select id="language-model" v-model="languageModel">
      <option value="gpt-4">GPT-4</option>
      <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
    </b-form-select>
    <b-button @click="sendQuestion" class="mt-2" variant="success">Run Workflow</b-button>
    <div v-if="responses.length > 0">
      <b-card v-for="(response, index) in responses" :key="index" class="mt-2">
        <b-card-text>
          <span v-html="response"></span>
        </b-card-text>
      </b-card>
      <b-button @click="summarizeAnswers" class="mt-2" variant="success">Run Answer Summary</b-button>
      <b-card class="mt-2">
        <b-card-text>
          <span v-html="summary"></span>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("workflow");
import { callCompletionApiFetch } from "./chatgpt";
import marked from "marked";

export default {
  data() {
    return {
      attachment: null,
      questionOrTask: "question",
      questionSet: [
`1. How many speakers are there in the conversation?
2. What are the questions asked during the conversation?
3. What are the next steps for the conversation?`,
`1. Who is Zack? What does he do?
2. What is his main feedback?`,
`Please summarize the conversation.`
      ],
      question: null,
      tasksSet: [
`Below is a transcript. Please extract all the questions asked by Rex in bulleted points.`,
      ],
      task: null,
      responses: [],
      summary: null,
      languageModel: "gpt-4",
    }
  },
  computed: {
    notes() { return this.$store.state.notes; },
    contentOnlyNotes() {
      return this.notes.filter((note) => note.tags.length == 0);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.question = this.questionSet[1];
      this.task = this.tasksSet[0];
    },
    sendQuestion() {
      this.responses = [];

      let lines = this.attachment.split("\n");
      let batches = [];
      let currentBatch = "";
      for (let i = 0; i < lines.length; i++) {
        if (currentBatch.length + lines[i].length > 6000) {
          batches.push(currentBatch);
          currentBatch = "";
        }
        currentBatch += lines[i];
      }
      if (currentBatch.length > 0) {
        batches.push(currentBatch);
      }
      log.log("Sending question", batches);
      let promises = [];
      let testLimit = 20;
      for (let i = 0; i < batches.length && i < testLimit; i++) {
        let batchIndex = i;
        let batchItem = batches[i];

let questionsContent = `Below is only part ${i+1} of a complete transcript: 
${batchItem}
end of part ${i+1}.
Here is some questions:
${this.question}
Please, let me know the answer to this question for this part of the transcript.`;

let simpleTaskContent = `${this.task}
${batchItem}`;

let taskContent = `Below is only part ${i+1} of a complete transcript: 
${batchItem}
end of part ${i+1}.
Please complete this task:
${this.task}`;

        let content = this.questionOrTask === 'question' ? questionsContent : simpleTaskContent || taskContent;
        let systemMessage = {
          "role": "system",
          "content": "You are a helpful assistant. Please reply in Markdown.",
        };
        let messages = [
          {
            "role": "user",
            "content": content,
          },
        ];
        let promise = callCompletionApiFetch(this.$store.config, 
          {
            model: this.languageModel,
            systemMessage, 
            messages
          }, 
          () => {},
          (updatedResult) => {
            updatedResult = marked.parse(updatedResult)
            this.$set(this.responses, batchIndex, updatedResult);
          },
          () => {},
        );
        promises.push(promise);
      }
    },
    summarizeAnswers() {
      let content = 
`We asked the following questions for each part of the transcript.
${this.question}

Here are the answers for each part:

${this.responses.join("\n\n")}

end of answers.

Please, answer the questions for the whole transcript without ommiting anything.`;
      let systemMessage = {
        "role": "system",
        "content": "You are a helpful assistant. Please reply in Markdown.",
      };
      let messages = [
        {
          "role": "user",
          "content": content,
        },
      ];
      callCompletionApiFetch(this.$store.config, 
        {
          model: this.languageModel,
          systemMessage, 
          messages
        }, 
        () => {},
        (updatedResult) => {
          updatedResult = marked.parse(updatedResult);
          this.summary = updatedResult;
        },
        () => {},
      );
    },
  }
}
</script>

<style>

</style>
